<template>
	<div>
		<div class="function_box">
			<div class="function_value" v-for="(item,index) in list" :key="index">
				<div class="icons">
					<img :src="item.url" />
					<span>{{item.name}}</span>
				</div>
				<div class="introduce">{{item.value}}</div>
				<div></div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['list'],
		data() {
			return {

			}
		},
		methods: {}
	}
</script>

<style scoped>
	.function_box {
		width: 50rem;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		margin: 0 auto 0;
	}

	.function_value {
		width: 15.6rem;
		height: 7.26rem;
		background: #FBFBFB;
		border-radius: 0.3rem;
		border: 0.06rem solid #EEEEEE;
		margin-top: 1.02rem;
		padding-top: 1.2rem;
		padding-left: 1.2rem;
		margin-right: 1.44rem;
		padding-right: 1.2rem;
	}

	.function_value:nth-child(3n) {
		margin-right: 0 !important;
	}

	.icons {
		display: flex;
		align-items: center;
	}

	.icons>img {
		width: 1.8rem;
		height: 1.8rem;
		margin-right: 0.42rem;
	}

	.icons>span {
		color: #333333;
		font-size: 1.32rem;
		font-weight: 500;
	}

	.introduce {
		width: 100%;
		color: #999999;
		font-size: 0.9rem;
		margin-top: 0.42rem;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
	}
</style>